.lazy-loading-main {
    height: 100vh !important;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 9999999;
}

.lazy-loading-main img {
    max-width: 250px;
}

.loader {
    display: block;
    --height-of-loader: 4px;
    --loader-color: #000000;
    width: 130px;
    height: var(--height-of-loader);
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    margin-top: .5rem;
}

.loader::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: moving 1s ease-in-out infinite;
    ;
}

@keyframes moving {
    50% {
        width: 100%;
    }

    100% {
        width: 0;
        right: 0;
        left: unset;
    }
}