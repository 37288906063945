* {
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0px !important;
}

html,
body,
#root {
    height: 100%;
    background-color: #fafafa;
}

#root {
    display: flex;
    flex-direction: column;
}

body.no-scroll {
    overflow: hidden !important;
}

html.no-scroll {
    overflow: hidden !important;
}

body {
    font-family: "Poppins", sans-serif !important;
}

.ytp-large-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 44px;
    height: 48px;
    margin-left: -34px;
    margin-top: -12px;
    -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    z-index: 63;
}

.lazy-load-image-background.blur.lazy-load-image-loaded{
    height: 100% !important;
    width: 100% !important;
}


@media (max-width: 1000px) {
    html {
        overflow-x: hidden;
    }
    body {
        overflow-x: hidden;
    }
}

@media (max-width: 1000px) {
    .navbar-expand-lg .navbar-nav {
        display: none !important;
    }
}