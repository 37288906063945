.ytp-large-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 46px !important;
    height: 48px !important;
    margin-left: -22px !important;
    margin-top: -24px;
    -webkit-transition: opacity .25s cubic-bezier(0, 0, .2, 1);
    transition: opacity .25s cubic-bezier(0, 0, .2, 1);
    z-index: 63;
}

/* .stable-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 20% 30%, rgba(255, 200, 150, 0.6), transparent 60%),
              radial-gradient(circle at 80% 70%, rgba(255, 100, 100, 0.5), transparent 60%),
              radial-gradient(circle at 50% 50%, rgba(150, 255, 150, 0.4), transparent 70%);
  z-index: -1;
  filter: blur(50px);
} */


@media (min-width:1200px) and (max-width:1500px) {
    .ytp-large-play-button {
        left: 50% !important;
        top: 50% !important;
        width: 35px !important;
        height: 47px !important;
        margin-left: -20px !important;
    }
}